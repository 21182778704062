<template>
    <div id="resources">
    <transition name="slide-fade" mode="out-in">
    <div class="mcontainer" v-if="this.$route.name === 'Resources' ">
        <div class="md:flex justify-between relative mb-3">
            <div class="flex-1">
                <h2 class="text-lg font-semibold"> Resources </h2>
                <p>Video, software, file yang berguna untuk dibagikan dan dimanfaatkan.</p>
                <br>
            </div>
            <div class="d-flex md:absolute right-0">
                <router-link :to="{ name: 'ApprovalResource' }" class="flex d-inline items-center justify-center px-5 py-2 rounded-md bg-blue-600 text-white mr-2" v-if="waitingApproval.length > 0">Waiting Approval ({{ waitingApproval.length }})</router-link>
                <router-link to="/app/resources/create" class="flex d-inline items-center justify-center px-5 py-2 rounded-md bg-blue-600 text-white">
                    <span> Create Resource </span>
                </router-link>
            </div>
        </div>
        <div class="flex row">
        <div class="col-md-3 order-md-2 mb-4 containerSticky">
            <div uk-sticky="offset:100" class="uk-sticky card p-3">
                <div class="mb-4">
                    <div class="header_search search-resource">
                        <i class="uil-search-alt"></i>
                        <input v-model="filter.search" v-on:keyup.enter="fetchSuggestion" type="text" placeholder="Search resource..." autocomplete="off" class="form-control" style="min-width: 100%;">
                    </div>
                </div>
                <h4 class="text-lg font-semibold mb-3"> Categories <a href="javascript:void(0)" @click.prevent="addCategory" style="float: right;font-weight: 400;font-size: 12px;" v-if="datauser.is_admin === 1">+ Add</a></h4>
                <ul class="list-checkbox" v-if="dataCategory && dataCategory.length > 0">
                    <li v-for="item in dataCategory" :key="item.id">
                        <b-form-checkbox id="checkbox-1" name="checkbox-1" :value="item.slug" unchecked-value="" v-model="checkedCategory" @change="fetchSuggestion()">
                            {{ item.title }}
                            <a href="javascript:void(0)" @click.prevent="editCategory(item)" class="btn-edit" v-if="datauser.is_admin === 1">Edit</a>
                        </b-form-checkbox>
                    </li>
                </ul>
                <p v-else class="mb-4">Category not found</p>
                <h4 class="text-lg font-semibold mb-3"> Type</h4>
                <ul class="list-checkbox">
                    <li>
                        <b-form-checkbox id="checkbox-1" name="checkbox-1" value="eBooks" unchecked-value="" v-model="checkedType" @change="fetchSuggestion()">
                            eBooks
                        </b-form-checkbox>
                        <b-form-checkbox id="checkbox-1" name="checkbox-1" value="Journals" unchecked-value="" v-model="checkedType" @change="fetchSuggestion()">
                            Journals
                        </b-form-checkbox>
                        <b-form-checkbox id="checkbox-1" name="checkbox-1" value="Videos" unchecked-value="" v-model="checkedType" @change="fetchSuggestion()">
                            Videos
                        </b-form-checkbox>
                        <b-form-checkbox id="checkbox-1" name="checkbox-1" value="Podcast" unchecked-value="" v-model="checkedType" @change="fetchSuggestion()">
                            Podcast
                        </b-form-checkbox>
                        <b-form-checkbox id="checkbox-1" name="checkbox-1" value="Articles" unchecked-value="" v-model="checkedType" @change="fetchSuggestion()">
                            Articles / Blog
                        </b-form-checkbox>
                        <b-form-checkbox id="checkbox-1" name="checkbox-1" value="Documents" unchecked-value="" v-model="checkedType" @change="fetchSuggestion()">
                            Documents
                        </b-form-checkbox>
                    </li>
                </ul>
                <h4 class="text-lg font-semibold mb-3"> Tags </h4>
                <div class="flex flex-wrap gap-2" v-if="dataTags">
                    <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full item-tags" :class="tagClass(item)" @click="selectTags(item)" v-for="item in dataTags" :key="item">{{ item }}</a>
                </div>
                <p v-if="dataTags.length === 0" class="mb-4">Tags not found</p>
            </div>
        </div>
        <div class="col-md-9 order-md-1">
            <div class="px-1 py-3">
                <div class="row" v-if="loading && resources.length > 0">
                    <CardVideo v-for="resource in resources" :key="resource.id" :data="resource" :type="'grid'" />
                </div>
                <div v-if="!loading">
                    <div class="row">
                        <div class="col-md-4 loading-space" v-for="index in 6" :key="index">
                        <content-placeholders :rounded="true">
                            <content-placeholders-img />
                            <content-placeholders-heading />
                        </content-placeholders>
                        </div>
                    </div>
                </div>
                <div v-if="loading_other">
                    <div class="row">
                        <div class="col-md-4 loading-space" v-for="index in 3" :key="index">
                        <content-placeholders :rounded="true">
                            <content-placeholders-img />
                            <content-placeholders-heading />
                        </content-placeholders>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center mt-6" v-if="loadmore_show">
                    <a href="javascript:void(0)" @click="loadmore()" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
                        Load more</a>
                </div>
            </div>
        </div>
        </div>
    </div>
    <router-view/>
    </transition>
    <b-modal v-model="category.form.show" :title="category.form.title" ok-title="Submit" :hide-footer="true">
        <form @submit.prevent="">
            <b-form-group>
                <b-form-input
                    v-model="category.form.data.title"
                    placeholder="Add a title"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group align="right">
                <div>
                    <b-button v-if="category.form.data.id" variant="danger" class="mr-2" @click="deleteCategory">
                        Delete
                    </b-button>
                    <b-button variant="primary" type="submit" @click="submitCategory" size="sm">
                        Submit
                    </b-button>
                </div>
            </b-form-group>
        </form>
    </b-modal>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex'
    import CardVideo from '@/components/CardVideo'
    import axios from 'axios'

    export default {
        name: 'Resources',
        computed: {
            ...mapState('auth', { datauser: 'user' }),
            ...mapState('categories', {
                dataCategory: state => state.data,
                dataTags: state => state.tags
            })
        },
        components: {
            CardVideo
        },
        data () {
            return {
                waitingApproval: [],
                checkedCategory: [],
                checkedType: [],
                checkedTags: [],
                loading: true,
                resources: [],
                filter: {
                    search: ''
                },
                category: {
                    form: {
                        title: '',
                        show: false,
                        loading: false,
                        data: {
                            id: '',
                            title: '',
                            type: 'news'
                        }
                    }
                },
                loading_other: false,
                loadmore_show: true,
                page: 1
            }
        },
        created () {
            this.fetchData()
        },
        watch: {
            $route: 'fetchData'
        },
        methods: {
            ...mapActions('categories', {
                _createCategory: 'create',
                _updateCategory: 'update',
                _deleteCategory: 'delete',
                _fetchCategory: 'fetch'
            }),
            fetchSuggestion () {
                const $this = this
                this.loading = false
                this.page = 1
                const categories = this.checkedCategory.join()
                const type = this.checkedType.join()
                const tags = this.checkedTags.join()
                axios.get('resource?search=' + this.filter.search + '&page=' + this.page + '&limit=12&category=' + categories + '&tags=' + tags + '&type=' + type).then(function (res) {
                    $this.resources = res.data.result.data
                    if ($this.resources.length < 12) {
                        $this.loadmore_show = false
                    }
                    $this.loading = true
                })
            },
            async fetchData () {
                this.loadWaitingApproval()
                this.loading = false
                try {
                    const res = await axios.get('resource?page=' + this.page + '&limit=12')
                    this.resources = res.data.result.data
                    if (this.resources.length < 12) {
                        this.loadmore_show = false
                    }
                    this.loading = true
                    this._fetchCategory({ type: 'resource' })
                } catch (e) {
                    console.error(e)
                }
            },
            loadmore () {
                this.loading_other = true
                const $this = this
                this.page = this.page + 1
                const categories = this.checkedCategory.join()
                const tags = this.checkedTags.join()
                axios.get('resource?search=' + this.filter.search + '&page=' + this.page + '&limit=3&category=' + categories + '&tags=' + tags).then(function (response) {
                    $this.loading_other = false
                    if (response.data.result.data.length === 0) {
                        $this.loadmore_show = false
                        $this.page = $this.page - 1
                    } else {
                        $this.resources.push(...response.data.result.data)
                    }
                })
            },
            loadWaitingApproval () {
                axios.get('resource?waiting_approval=1').then(response => {
                    if (response.data.result) {
                        this.waitingApproval = response.data.result
                    }
                })
            },
            submitCategory () {
                this.category.form.loading = true
                const { id, title, type } = this.category.form.data
                const promise = id ? this._updateCategory({ id, data: { title, type } }) : this._createCategory({ title, type })
                promise.then(() => {
                    this._fetchCategory({ type: 'resource' })
                    this.category.form.show = false
                    this.category.form.data = { id: '', title: '' }
                })
                    .finally(() => {
                        this.category.form.loading = false
                    })
            },
            addCategory () {
                this.category.form.title = 'Add Category'
                this.category.form.show = true
                this.category.form.data.id = ''
                this.category.form.data.title = ''
                this.category.form.data.type = 'resource'
            },
            editCategory (item) {
                this.category.form.title = 'Edit Category'
                this.category.form.show = true
                this.category.form.data = { ...item }
            },
            deleteCategory () {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.category.form.loading = true
                        const { id } = this.category.form.data
                        this._deleteCategory(id)
                            .then(() => {
                                this._fetchCategory({ type: 'resource' })
                                this.category.form.show = false
                                this.category.form.data = { id: '', title: '' }
                                this.$swal(
                                    'Deleted!',
                                    'Your category has been deleted.',
                                    'success'
                                )
                            })
                            .finally(() => {
                                this.category.form.loading = false
                            })
                    }
                })
            },
            tagClass (tag) {
                const isSelected = this.checkedTags.includes(tag)
                return {
                    active: isSelected,
                    '': !isSelected
                }
            },
            selectTags (tag) {
                const index = this.checkedTags.findIndex(t => t === tag)
                if (index >= 0) {
                    this.checkedTags.splice(index, 1)
                } else {
                    this.checkedTags.push(tag)
                }
                this.fetchSuggestion()
            }
        }
    }
</script>
